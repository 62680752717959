<template>
  <b-card title="Sales Order List ">
    <!-- <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong>Select download file type</strong>
    <div style="display: table; margin: 0 auto;">
      <download-excel :data="order" :fields="filter" name="RSP Order.xls" class="download-border">
        <img src="@/assets/images/xls.png" height=100>
      </download-excel>
      <download-excel :data="order" :fields="filter" type="csv" name="RSP Order.csv" class="download-border">
        <img src="@/assets/images/csv.png" height=100>
      </download-excel>
    </div>
    </b-modal> -->
    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          Start Date
          <b-form-datepicker  @input="(value) => fireFiltering('startDate', value)" :max="maxDate" v-model="startDate"/>
        </b-col>
        <b-col cols="5">
          End Date
          <b-form-datepicker  @input="(value) => fireFiltering('endDate', value)" :max="maxDate" v-model="endDate"/>
        </b-col>
        <b-col cols="2">
            <!-- <b-button variant="primary" style="margin-top:20px" @click="promptDownload=true">
              <feather-icon size="1x" icon="DownloadIcon" /> Download
            </b-button> -->
            <b-button variant="primary" style="margin-top:20px">
            <download-excel :data="order" :fields="filter" name="RSP Order.xls" class="download-border">download</download-excel>  
            </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="8"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>

        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="order"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoading"
            :current-page="currentPage"
            responsive
            show-empty
            empty-text="Data Not Found"
          >
          <template #cell(DONumber)="row">
            <div v-for="(dos,index) in row.item.DONumber" :key="index">
              {{dos}},
            </div>
          </template>
          <template #cell(deliveryDate)="row">
            {{dates(row.item.deliveryDate)}}
          </template>
          </b-table>
        </b-col>


        <b-col cols="12">
          <b-pagination
            :total-rows="order.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx'
import moment from 'moment';
import axios from '@/axios';
import { dateFormat } from '@/utils/utils'

export default {
  data() {
    return {
      fields: [
        { key: 'orderDate', label: 'Order Date', filterable: true, sortable: true },
        { key: 'PONumber', label: 'PO Number', filterable: true, sortable: true },
        { key: 'status', label: 'Status', filterable: true, sortable: true },
        { key: 'fulfilmentType', label: 'Fulfilment Type', filterable: true, sortable: true, },
        { key: 'deliveryType', label: 'Delivery Type', filterable: true, sortable: true },
        { key: 'deliveryDate', label: 'Delivery Date', filterable: true, sortable: true },
        { key: 'sapSo', label: 'SO Number', filterable: true, sortable: true },
        { key: 'DONumber', label: 'DO Numbers', filterable: true, sortable: true },
      ],      
      filter: {
        'Order Date'      : "orderDate",
        'PO Number'    : 'PONumber',
        'Status'     : 'status',
        'Fulfilment Type'           : 'fulfilmentType',
        'Delivery Type'            : 'deliveryType',
        'Delivery Date'            : 'deliveryDate',
        'SO Number'              : 'sapSo',
        'DO Numbers'              : 'DONumber',
      },
      search: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,

      startDate: '',
      endDate: '',
      maxDate: '',
      busyLoading: true,
      promptDownload: false,
    }
  },
  mounted() {
    this.orderList({
      startDate : this.reactiveStartDate, 
      endDate : this.reactiveEndDate})
    .then(() => {
      this.busyLoading = false;
    });
  },
  computed: {
    ...mapGetters({
      order : 'report/order'
    }),
    ...mapState({
      filterOrder: (state) => {
        if (state.filter.filterContainer['report-order-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-order-list'];
          return orderFilter;
        }

        return {};
      },
    }),
   reactiveStartDate: {
      get() {
        if(this.filterOrder.startDate === undefined){
          this.filterOrder.startDate = this.startDate
        }
      
        return this.filterOrder.startDate;
      },
      set() {
        this.updateFilterOrder({
          name: 'report-order-list',
          data: {
            startDate: this.filterOrder.startDate
          }
        });

      }
    },
    reactiveEndDate: {
      get() {
        if(this.filterOrder.endDate === undefined){
          this.filterOrder.endDate = this.endDate;
        }
        return this.filterOrder.endDate;
      },
      set() {
        this.updateFilterOrder({
          name: 'report-order-list',
          data: {
            endDate: this.filterOrder.endDate
          }
        });

      }
    },   
  },
  methods: {
    ...mapActions({
      orderList : 'report/getOrder',
      getFilterOrder: 'filter/getFilter',
      updateFilterOrder: 'filter/updateFilter'
    }),
    dates(date){
      return dateFormat(date)
    },
    saveFilter(key, value) { //save updated filter
      let tempFilter = this.filterOrder; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterOrder({ //save to store named is "filter"
        name: 'report-order-list',
        data: { ...tempFilter }
      });
    },
    fireFiltering(key, value) {
      this.saveFilter(key, value); //filter saved date
      
      let filter = this.filterOrder; 

      this.busyLoading = true;
      this.orderList({ ...filter}).then((data) => {
        this.busyLoading = false;
      });
    },
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterOrder('report-order-list');

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    let monthAgo= new Date();
    monthAgo.setMonth(monthAgo.getMonth()-1);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now

    document.title = 'RSP Order Report | RSP'
  },
}
</script>
